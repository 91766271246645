.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box {
    background-color: rgba(255, 68, 0, 0.349);
    padding: 2rem;
    min-width: 30rem;
    border-radius: 7px;
}

.anticon {
    vertical-align: 0;
}

.container-fluid {
    padding: 0 5%;
}

.status-button {
    border-radius: 5px;
    border: 1px solid black;
}

.status-button:hover {
    background-color: #ff6200;
    color: white;
}

.ap-button {
    background-color: #ff6200;
    color: white;
    border: 1px solid #282c34;
}

.ap-button:hover {
    background-color: white;
    color: black;
    border: 1px solid #ff6200;
}

.ap-button:focus {
    box-shadow: 0 0 0 0.2rem #ff6200;
}

.ap-logout {
    color: #282c34;
    font-size: 24px;
}

.ap-logout:hover {
    background-color: #ff6200;
    color: white;
    border: 1px solid #282c34;
}

.check-wrapper {
    border-bottom: 1px solid black;
    padding: 7px 20px;
}

.check-wrapper:last-child {
    border-bottom: 0;
}

.check-col {
    border: 1px solid black;
    border-radius: 5px;
    padding-right: 0px;
    padding-left: 0px;

    margin: 0 20px;
}

.check-wrapper label {
    width: 100%;
}

.edit-btn {
    border: 1px solid #ced4da;
}

.light-text {
    color: #707070;
}

.header-stats {
    font-size: 24px;
}

.header-stats > span {
    font-weight: bold;
}

.header {
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    margin: 2rem 0;
    flex-wrap: wrap;
}

.header .block {
    display: flex;
    flex-direction: "row";
    align-items: flex-end;
}

.header .right-block {
    display: flex;
    margin-top: 40px;
}

.header .left-block {
    margin-right: 50px;
}

.header p {
    margin: 0;
    padding-bottom: 1px;
}

.header .btn {
    margin: 0 10px;
    padding: 0 10px;
}

.header .logo {
    width: 150px;
    height: 100px;
    margin-right: 50px;
}

.header h1 {
    margin: 0;
}

.collapse-btn {
    font-weight: bold;
    font-size: 20px;
}

.collase-btn:focus {
    box-shadow: 0;
}

.filter-collapse {
    border: 3px solid black;
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 10px;
    background-color: #f4f5f7;
    border-radius: 2px;
}

.filter-collapse input,
.filter-collapse select {
    border: 1px solid black;
}

.user-table tr {
    cursor: pointer;
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48.75px;
}

.card-row > p,
.card-row > input {
    flex: 1;
}
.card-row p {
    margin: 0;
}

.user-table table {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.user-input {
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.user-filter input {
    max-width: 20rem;
}

.navbar-expand {
    justify-content: space-between;
}

.orange-text {
    color: #ff6200;
}

.edit .form-group {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.edit .edit-block {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.edit .form-group p {
    margin: 0;
}

.edit .form-group:last-child {
    margin-bottom: 0;
}

.edit .form-group label:last-child {
    margin-bottom: 0;
}

.edit .form-group textarea {
    display: block;
    width: 100%;
}

.img-row {
    display: flex;
    flex-wrap: wrap;
}

.image-block {
    text-align: center;
}

.image-block button {
    margin-top: 10px;
}

.submit-col {
    text-align: center;
}

.log-entry p {
    margin: 0;
}

.log-entry {
    padding: 20px;
    background-color: #ff620038;
    margin-bottom: 1.5rem;
}

.log-entry:last-child {
    margin-bottom: 0;
}

.switch-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.switch-row p {
    margin: 0;
}

.service-container {
    padding: 10px;
}
.service-container:nth-child(even) {
    background-color: rgba(211, 211, 211, 0.329);
}

.service-input-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.service-input-images {
    width: 50px;
    height: 50px;
    border: 2px dashed gray;
    border-radius: 10px;
    margin-right: 10px;
}

.service-input-images:hover {
    border: 2px dashed black;
}

.service-input-images p {
    margin: 0;
    text-align: center;
    font-size: 15px;
}

.modal .select-search {
    width: 100%;
}

.forgot-password-link {
    font-size: 0.9rem;
}

.forgot-password-link:hover {
    text-decoration: none;
    color: #004186;
}
